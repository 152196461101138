import axios from 'axios'
import qs from 'qs'
import {DateTime} from "luxon";
import Vue from 'vue'

export default {
	namespaced: true,
	state: {
		holdPrice: 0,
		restPointsAmount: 0,
		restaurantDetail: null,
		restDeliveryTime: null,
	},
	mutations: {
		setHoldPrice(state, payload)
		{
			state.holdPrice = payload;
		},
		setRestPointsAmount(state, payload)
		{
			state.restPointsAmount = payload;
		},
		setRestaurantDetail(state, payload)
		{
			state.restaurantDetail = payload;
		},
		setRestDeliveryTime(state, payload)
		{
			state.restDeliveryTime = payload;
		}
	},
	getters: {
		getRestDeliveryTime(state)
		{
			return state.restDeliveryTime;
		},
	},
	actions: {
		async loadRestPoints({rootState, commit})
		{
			let data = qs.stringify({
				'currentOwnerRestaurant': rootState.currentOwnerRestaurant,
			});
			let resp = await axios.post('/restaurant/getRestaurantPoints/', data);
			commit('setHoldPrice', resp.data.frozenPoints);
			commit('setRestPointsAmount', resp.data.restPoints);
		},
		async doPointsOut({rootState, commit, dispatch}, sum)
		{
			let data = qs.stringify({
				'sum': sum,
				'currentOwnerRestaurant': rootState.currentOwnerRestaurant,
			});
			let resp = await axios.post('/restaurant/pointsOut/', data);
			if (resp.data.success)
			{
				dispatch('loadRestPoints');
			}
		},
		async loadRestaurantDetail({rootState, commit})
		{
			let data = qs.stringify({
				'currentOwnerRestaurant': rootState.currentOwnerRestaurant,
			});
			let resp = await axios.post('/restaurant/getRestaurantDetail/', data);
			commit('setRestaurantDetail', resp.data.restaurantDetail);
		},
		async getRestDeliveryTime({rootState, commit}, id)
		{
			let data = qs.stringify({
				'restId': rootState.currentRestarauntId || id,
			});

			let resp = await axios.post('/restaurant/getDeliveryTimes', data);
			commit('setRestDeliveryTime', resp.data.delivery_time_range);
		}
	}
}
